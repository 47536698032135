body,
html,
.ant-layout {
  position: relative;
  background-color: #eff3fc;
}
.login-cont {
  height: 100%;
  /*
  background: #f8f9fc url(../../assets/image/login-backage.png) left top
    no-repeat;
  background-size: 70% 100%;
  */
}
.login-cont .copyright {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.login-cont .copyright > p {
  font-size: 12px;
  font-weight: 400;
  font-family: SourceHanSansSC-Regular, SourceHanSansSC;
  color: rgba(0, 0, 0, 0.45);
  text-decoration: none;
}
.login-cont .login-register {
  height: 520px !important;
}
.login-cont .login-register.check {
  position: static;
  height: auto !important;
}
.login-cont .login-sign,
.login-cont .login-register {
  width: 360px;
  height: 430px;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 4px;
  padding: 40px;
}
.login-cont .login-sign h1,
.login-cont .login-register h1 {
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 32px;
  margin: 0;
}
.login-cont .login-sign p,
.login-cont .login-register p {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  margin: 5px 0 24px;
}
.login-cont .login-sign .ant-form .ant-form-item,
.login-cont .login-register .ant-form .ant-form-item {
  margin-bottom: 20px;
}
.login-cont .login-sign .ant-form .ant-form-item .ant-input-affix-wrapper,
.login-cont .login-register .ant-form .ant-form-item .ant-input-affix-wrapper {
  border-radius: 5px;
}
.login-cont .login-sign .ant-form .ant-input-affix-wrapper > .ant-input,
.login-cont .login-register .ant-form .ant-input-affix-wrapper > .ant-input {
  font-size: 14px;
}
.login-cont .login-sign .ant-form .ant-btn-lg,
.login-cont .login-register .ant-form .ant-btn-lg {
  font-size: 14px;
  border-radius: 5px;
}
.login-cont .login-sign .agreement .ant-checkbox-wrapper,
.login-cont .login-register .agreement .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.login-cont .login-sign .agreement .ant-checkbox-wrapper .ant-checkbox,
.login-cont .login-register .agreement .ant-checkbox-wrapper .ant-checkbox {
  top: 0;
}
.login-cont .login-sign .agreement label,
.login-cont .login-register .agreement label {
  font-size: 12px;
}
.login-cont .login-sign .form-agreement,
.login-cont .login-register .form-agreement {
  font-size: 12px;
  font-weight: 400;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  margin-top: 76px;
  text-align: center;
}
