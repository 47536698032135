.usercenter .context.agent-device > .actions {
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.usercenter .context.agent-device .device-list {
  border: 1px solid #eee;
  border-top: 0;
}
.usercenter .context.agent-device .device-list .device-list-head .item,
.usercenter .context.agent-device .device-list .device-list-item .item {
  padding: 0 15px;
}
.usercenter .context.agent-device .device-list .device-list-head .item.id,
.usercenter .context.agent-device .device-list .device-list-item .item.id {
  width: 10%;
}
.usercenter .context.agent-device .device-list .device-list-head .item.user,
.usercenter .context.agent-device .device-list .device-list-item .item.user {
  width: 40%;
}
.usercenter .context.agent-device .device-list .device-list-head .item.price,
.usercenter .context.agent-device .device-list .device-list-item .item.price {
  width: 20%;
}
.usercenter .context.agent-device .device-list .device-list-head .item.time,
.usercenter .context.agent-device .device-list .device-list-item .item.time {
  width: 30%;
}
.usercenter .context.agent-device .device-list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  background-color: #fafafa;
}
.usercenter .context.agent-device .device-list-head .item {
  padding: 0 15px;
  border-right: 1px solid #eee;
}
.usercenter .context.agent-device .device-list-head .item:last-child {
  border-right: 0;
}
.usercenter .context.agent-device .device-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  padding: 10px 0;
  transition: all 0.3s;
}
.usercenter .context.agent-device .device-list-item:hover {
  background-color: rgba(250, 250, 250, 0.5);
}
