.usercenter .context.order .head,
.usercenter .context.order .goods-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.usercenter .context.order .head .item,
.usercenter .context.order .goods-list-item .item,
.usercenter .context.order .head td,
.usercenter .context.order .goods-list-item td {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.usercenter .context.order .head .item.intro,
.usercenter .context.order .goods-list-item .item.intro,
.usercenter .context.order .head td.intro,
.usercenter .context.order .goods-list-item td.intro {
  width: 40%;
}
.usercenter .context.order .head .item.amount,
.usercenter .context.order .goods-list-item .item.amount,
.usercenter .context.order .head td.amount,
.usercenter .context.order .goods-list-item td.amount {
  width: 15%;
  text-align: center;
}
.usercenter .context.order .head .item.contact,
.usercenter .context.order .goods-list-item .item.contact,
.usercenter .context.order .head td.contact,
.usercenter .context.order .goods-list-item td.contact {
  width: 175px;
  text-align: center;
}
.usercenter .context.order .head .item.user,
.usercenter .context.order .goods-list-item .item.user,
.usercenter .context.order .head td.user,
.usercenter .context.order .goods-list-item td.user {
  width: 15%;
  text-align: center;
}
.usercenter .context.order .head .item.actions,
.usercenter .context.order .goods-list-item .item.actions,
.usercenter .context.order .head td.actions,
.usercenter .context.order .goods-list-item td.actions {
  flex: 1 1;
}
.usercenter .context.order > .head {
  padding: 5px 0;
  background-color: #f5f5f5;
  margin-bottom: 15px;
}
.usercenter .context.order > .head > .item {
  padding: 0 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.usercenter .context.order > .head > .item:last-child {
  border-right: 0;
}
.usercenter .context.order > .body .list > .list-item {
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
}
.usercenter .context.order > .body .list > .list-item > .head {
  padding: 5px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.usercenter .context.order > .body .list > .list-item > .head .order {
  display: flex;
  align-items: center;
}
.usercenter .context.order > .body .list > .list-item > .head .order .item {
  margin-right: 15px;
}
.usercenter .context.order > .body .list > .list-item > .order {
  padding: 5px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.usercenter .context.order > .body .list > .list-item > .order .item {
  min-width: 160px;
  margin-right: 15px;
}
.usercenter .context.order > .body .list > .list-item .goods-list {
  position: relative;
  width: 100%;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item {
  position: relative;
  display: table-row;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item {
  position: relative;
  padding: 10px 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item:last-child {
  border-right: none;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.intro {
  align-items: center;
  justify-content: space-between;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.intro .cont {
  display: flex;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.intro .cont .cover {
  display: inline-flex;
  width: 60px;
  height: 60px;
  position: relative;
  align-items: center;
  transition: all 0.3s 0s linear;
  cursor: pointer;
  overflow: hidden;
  justify-content: center;
  margin-right: 15px;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.intro .cont .cover img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.intro .cont .info .title {
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 5px;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.intro .cont .info .sku {
  font-size: 12px;
  color: #999;
  max-width: 205px;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.contact {
  font-size: 12px;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.contact > div {
  flex: 1 1;
  line-height: 30px;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.user {
  font-size: 12px;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.user > div {
  flex: 1 1;
  line-height: 30px;
}
.usercenter .context.order > .body .list > .list-item .goods-list .goods-list-item .item.actions {
  justify-content: left;
}
.usercenter .context.order > .body .list > .list-item > .express {
  padding: 5px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.usercenter .context.order > .body .list > .list-item > .express .item {
  min-width: 160px;
  margin-right: 15px;
}
